import type { ApiData, Metadata } from './types';
import type { RouteParams } from 'nextjs/types';

import type { Route } from 'nextjs-routes';

import config from 'configs/app';

import getCanonicalUrl from './getCanonicalUrl';
import getPageOgType from './getPageOgType';

export default function generate<Pathname extends Route['pathname']>(route: RouteParams<Pathname>, apiData: ApiData<Pathname> = null): Metadata {

  const title = apiData ?
    'RWALayer Testnet Blockchain Explorer' :
    'RWALayer Testnet Blockchain Explorer';
  const description =
    'RWALayer Builds Its Own Layer-2 Network On Top Of Ethereum, Using A Low-Cost, ' +
    'Secure, Safe, And Private Optimistic Rollup Technology.';

  const pageOgType = getPageOgType(route.pathname);

  return {
    title: title,
    description,
    opengraph: {
      title: title,
      description: pageOgType !== 'Regular page' ? config.meta.og.description : '',
      imageUrl: pageOgType !== 'Regular page' ? config.meta.og.imageUrl : '',
    },
    canonical: getCanonicalUrl(route.pathname),
  };
}
